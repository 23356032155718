<template>
  <div class="flex mb-40 health-stats">
    <div class="health-sector">
      <div class="health-sector-title">गुनासो</div>
      <img
        src="../../assets/images/img_grievance.svg"
        alt="गुनासो"
        width="64"
        height="64"
      />
    </div>
    <div class="flex count-wrap">
      <div class="count">
        <span>{{ totalCount ? totalCount : "-" }}</span>
        कुल गुनासो/ प्रतिक्रिया प्राप्त
      </div>
      <div class="count">
        <span>{{ closedCount ? closedCount : "-" }}</span>
        समाधान भईसकेको
      </div>
      <div class="count">
        <span>{{ ongoingCount ? ongoingCount : "-" }}</span>
        समाधान हुँदै
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    totalCount: { type: String, required: false },
    ongoingCount: { type: String, required: false },
    closedCount: { type: String, required: false },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.title-filter {
  align-items: center;
  justify-content: space-between;
}

.health-stats {
  align-items: center;
  background: $neutrals-white;
  border-radius: 20px;
  @media all and (max-width: 768px) {
    display: block;
  }
}

.health-sector {
  background: $primary;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  text-align: center;
  padding: 40px;
  width: 200px;

  &-title {
    color: $neutrals-white;
    font-size: 24px;
    font-family: "Mukta-Bold";
    margin-bottom: 20px;
  }
  @media all and (max-width: 768px) {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    padding: 20px;
    width: 100%;
  }
}
</style>
